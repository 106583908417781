.button-thank{
  width:100%; 
  border:1px solid #000; 
  background: none; 
  border-radius:30px; 
  padding:10px;
  display: flex;
}
.button-thank a:hover{
  color: white;
}
.button-thank:hover {
  border: 1px solid #064171;
  background: #064171;
  color: white !important;
  transition: all 0.2s;
}

body {
  font-family: "Assistant", sans-serif;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}
body .img-magnifier {
  position: absolute;
  display: inline-block;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  background-color: rgba(255, 255, 255, 0.55);
}
body .img-magnifier i {
  color: #004072;
  font-size: 14px;
}
body header .top {
  background-color: #949494;
  padding: 6px 20px;
}
body header .top a {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  margin-right: 35px;
  text-decoration: none;
  line-height: 20px;
  vertical-align: middle;
}
body header .top a i {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  margin-right: 4px;
}
body header .bottom {
  padding: 6px 20px;
  background-color: #f0edea;
  border-left: 14px solid #000b1b;
  vertical-align: middle;
}
body header .bottom img {
  display: inline-block;
  height: 50px;
  vertical-align: middle;
  margin-top: 15px;
}
body header .bottom nav {
  text-align: right;
}
body header .bottom nav a {
  display: inline-block;
  height: 80px;
  line-height: 80px;
  vertical-align: middle;
  text-align: center;
  color: #707070;
  font-size: 16px;
  text-decoration: none;
  padding: 0px 12px;
  position: relative;
}
body header .bottom nav a::after {
  content: " ";
  position: absolute;
  bottom: calc(50% - 15px);
  width: 60px;
  height: 2px;
  background-color: #707070;
  left: 50%;
  margin-left: -30px;
  opacity: 0;
  transition: all 0.35s;
}
body header .bottom nav a:hover::after {
  opacity: 1;
}
body header .bottom nav a:last-child {
  background-color: #004072;
  color: #fff;
  text-align: center;
  height: 44px;
  line-height: 44px;
  padding: 0px 25px;
  margin-right: 60px;
  margin-left: 20px;
  opacity: 0.75;
  transition: all 0.35s;
}
body header .bottom nav a:last-child:hover {
  opacity: 1;
}
body header .bottom nav a:last-child:hover::after {
  display: none;
}
body main {
  width: 1280px;
  margin: 0px auto;
  padding: 80px 20px;
}
body main .welcome {
  margin-bottom: 80px;
}
body main .welcome h1 {
  font-family: "Cormorant", serif;
  font-size: 73px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #004072;
}
body main .welcome p {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #004072;
}
body main .step {
  margin-bottom: 110px;
  border-bottom: 1px solid #004072;
  padding-bottom: 40px;
}
body main .step.hidden {
  display: none;
}
body main .step big {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.67;
  letter-spacing: normal;
  text-align: left;
  color: #004072;
  margin-bottom: 10px;
}
body main .step section {
  padding-left: 45px;
}
body main .step section legend {
  display: block;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #004072;
  margin-bottom: 20px;
}
body main .step section legend b {
  display: block;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #ff0000;
  margin-bottom: 70px;
}
body main .step section legend b a {
  color: #ff0000;
  text-decoration: none;
}
body main .step section .main-products-holder {
  display: flex;
  align-items: center;
}
body main .step section .main-products-holder .main-product-item {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  margin: 0px 20px;
  cursor: pointer;
  border: solid 3px #FFF;
  padding: 15px;
  border-radius: 28px;
}
body main .step section .main-products-holder .main-product-item:first-child {
  margin-left: 0px;
}
body main .step section .main-products-holder .main-product-item:last-child {
  margin-right: 0px;
}
body main .step section .main-products-holder .main-product-item h2 {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #004072;
  text-decoration: underline;
  margin-bottom: 10px;
}
body main .step section .main-products-holder .main-product-item .img-holder {
  position: relative;
  height: 240px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
}
body main .step section .main-products-holder .main-product-item.selected {
  border: solid 3px #0cbf43;
}
body main .step section .options {
  font-size: 0px;
  max-width: calc(100% - 80px);
}
body main .step section .options .slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -45px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  vertical-align: middle;
  font-size: 30px;
  background: transparent;
  border: none;
  z-index: 2;
  text-align: center;
  padding: 0px;
  left: -40px;
  cursor: pointer;
  border: solid 2px #707070;
  border-radius: 50%;
}
body main .step section .options .slick-arrow i {
  display: inline-block;
  width: 35px;
  height: 35px;
  vertical-align: middle;
  text-align: center;
  margin-top: -12px;
  margin-left: 0px;
}
body main .step section .options .slick-arrow.r {
  left: unset;
  right: -80px;
}
body main .step section .options .slick-arrow.r i {
  margin-top: -12px;
  margin-left: 6px;
}
body main .step section .options .slick-dots {
  display: none !important;
}
body main .step section .options button {
  background-color: transparent;
  border: none;
  text-align: left;
  position: relative;
  padding-bottom: 50px;
  transition: all 0.35s;
}
body main .step section .options button::after {
  display: inline-block;
  content: "Elegir";
  position: absolute;
  bottom: 0px;
  left: 50%;
  margin-left: -35px;
  width: 70px;
  height: 38px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  background-color: #004072;
  border-radius: 19px;
  line-height: 38px;
  vertical-align: middle;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s;
}
body main .step section .options button .content {
  display: flex;
  align-items: center;
  border: solid 3px #FFF;
  padding: 15px;
  border-radius: 28px;
  overflow: hidden;
  flex-wrap: wrap;
  flex-direction: column;
}
body main .step section .options button .content a.link-title {
  display: flex;
  width: 100%;
  font-size: 22px;
  font-weight: 400;
  color: #646464;
  text-decoration: none;
  margin-bottom: 15px;
}
body main .step section .options button .content .img-holder {
  position: relative;
  display: flex;
  width: 100%;
  vertical-align: top;
  background-color: #f2f2f2;
  min-width: 120px;
  height: 152px;
  margin-right: 24px;
  transition: all 0.35s;
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
}
body main .step section .options button:hover {
  opacity: 1;
}
body main .step section .options button:hover::after {
  visibility: visible;
  opacity: 1;
}
body main .step section .options button:hover .content a {
  text-decoration: underline;
  color: #004072;
  text-decoration-thickness: 1px;
}
body main .step section .options button:hover .content .img-holder {
  position: relative;
  box-shadow: 0px 3px 5px 0px rgba(179, 179, 179, 0.81);
}
body main .step section .options button.selected {
  opacity: 1;
}
body main .step section .options button.selected::after {
  opacity: 0;
  visibility: hidden;
}
body main .step section .options button.selected .content {
  border: solid 3px #0cbf43;
}
body main .step section .options button.selected .content a {
  color: #004072;
}
body main .step section .options button.acabado {
  width: 30%;
}
body main .step section .options button.acabado .img-holder {
  background-size: contain;
  height: 275px !important;
}
body main .step section .form-holder .summary h2 {
  font-family: "Cormorant", serif;
  font-size: 40px;
  text-align: left;
  color: #ff0000;
  margin-bottom: 28px;
}
body main .step section .form-holder .summary .img-holder {
  position: relative;
  width: 114px;
  height: 114px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
body main .step section .form-holder .summary p {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #004072;
}
body main .step section .form-holder h3 {
  font-family: "Cormorant", serif;
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.6px;
  text-align: left;
  color: #004072;
  margin-bottom: 20px;
}
body main .step section .form-holder input[type=text], body main .step section .form-holder textarea, body main .step section .form-holder select {
  height: 43px;
  border-radius: 25px;
  background-color: #f2f2f2;
  width: calc(100% - 30px);
  padding: 0px 15px;
  border: none;
}
body main .step section .form-holder input[type=text].error, body main .step section .form-holder textarea.error, body main .step section .form-holder select.error {
  border: 1px solid red;
}
body main .step section .form-holder select {
  width: 100%;
}
body main .step section .form-holder textarea {
  height: 70px;
  resize: none;
  padding: 15px 15px;
}
body main .step section .form-holder button {
  display: inline-block;
  float: right;
  height: 48.1px;
  border-radius: 34px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #7acc45;
  border: none;
  padding: 0px 60px;
  color: #fff;
}
body main .step section .form-holder legend {
  font-size: 15px;
  color: #FF0000;
}
body main .step section .form-holder legend a {
  color: #FF0000;
  text-decoration: underline;
}
body main .step h2 {
  display: block;
  font-size: 20px;
  font-weight: bold;
}
body main .step .acabados {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  padding: 20px;
}
body main .step .acabados .acabado-holder {
  flex-basis: 0;
  flex-grow: 1;
  margin-bottom: 40px;
  margin: 10px;
  opacity: 0.65;
  cursor: pointer;
  transition: all 0.35s;
  border: solid 3px #fff;
  padding: 10px;
  border-radius: 19px;
}
body main .step .acabados .acabado-holder .img-holder {
  height: 180px;
  position: relative;
}
body main .step .acabados .acabado-holder span {
  display: block;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.7px;
  text-align: center;
  color: #646464;
  margin-top: 25px;
}
body main .step .acabados .acabado-holder:hover {
  opacity: 1;
}
body main .step .acabados .acabado-holder.selected {
  opacity: 1;
  border: solid 3px #0cbf43;
}
body main .step .fields {
  display: flex;
  align-items: center;
  align-content: center;
}
body main .step .fields fieldset {
  flex-basis: 0;
  flex-grow: 1;
  padding: 10px 10px;
}
body main .step .fields fieldset input, body main .step .fields fieldset select {
  height: 80px;
  border-radius: 25px;
  border: solid 1px #707070;
  vertical-align: top;
  width: calc(100% - 40px);
  padding: 0px 20px;
}
body main .step .fields fieldset select {
  width: 100%;
}
body main .step .fields fieldset label {
  font-size: 18px;
  color: #004072;
  display: block;
  text-align: center;
}
body main .step .fields fieldset label strong {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #ff0000;
}
body main .step .fields fieldset.error select, body main .step .fields fieldset.error input {
  border: 1px solid red !important;
}
body main .step small {
  font-size: 16px;
  border-top: 1px solid #EDEDED;
  display: block;
  width: 100%;
  margin-top: 15px;
  padding-top: 15px;
  color: #FF0000;
}
body main .step small a {
  color: #FF0000;
}
body footer {
  background-color: #004072;
  color: #fff;
}
body footer .icons-area {
  width: 100%;
  margin: 0px auto;
  padding: 60px 0px;
}
body footer .icons-area h2 {
  font-size: 60px;
  font-family: "Cormorant", serif;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.45);
  margin-bottom: 20px;
  padding-bottom: 20px;
}
body footer .icons-area h3 {
  font-size: 16px;
  text-align: center;
}
body footer .icons-area .icons-holder {
  display: flex;
  margin-top: 40px;
}
body footer .icons-area .icons-holder .icon {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 1;
}
body footer .icons-area .icons-holder .icon a {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #fff;
  text-decoration: none;
}
body footer .icons-area .icons-holder .icon a img {
  max-width: 100%;
  height: 150px;
}
body footer .icons-area .icons-holder .icon a b {
  display: inline-block;
  width: 100%;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
  vertical-align: top;
}
body footer .icons-area .icons-holder .icon a p {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  text-transform: uppercase;
  vertical-align: top;
}
body footer .site-map {
  padding: 80px 20px;
  text-align: center;
}
body footer .site-map .logo {
  display: inline-block;
  margin-bottom: 30px;
}
body footer .site-map .logo img {
  display: inline-block;
  height: 40px;
}
body footer .site-map .social-icons {
  display: flex;
}
body footer .site-map .social-icons a {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 1;
  color: #fff;
}
body footer .site-map .social-icons a i {
  font-size: 30px;
}
body footer .site-map h2 {
  font-family: "Cormorant", serif;
  font-size: 20px;
  font-weight: 600;
  display: block;
  text-align: left;
  margin-bottom: 15px;
}
body footer .site-map ul li {
  display: block;
  text-align: left;
  margin-bottom: 7px;
}
body footer .site-map ul li a {
  display: block;
  color: rgba(255, 255, 255, 0.45);
  text-decoration: none;
}
body footer .site-map address {
  text-align: left;
  margin-bottom: 20px;
}
body footer .site-map address h3 {
  display: block;
  width: 100%;
  font-weight: 600;
  margin-bottom: 5px;
}
body footer .site-map address a {
  display: inline-block;
  width: 100%;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.65);
  margin-bottom: 5px;
  text-decoration: none;
}
body footer .site-map address p {
  display: inline-block;
  width: 100%;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.65);
  margin-bottom: 5px;
}
body footer .copyright {
  display: block;
  background-color: #dad7d3;
  color: #707070;
  padding: 10px 0px;
  font-size: 13px;
  text-align: center;
}
body .modal-image {
  position: relative;
}
body .modal-image .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.85);
}
body .modal-image [role=dialog] {
  border-radius: 19px;
  padding: 65px 0px;
}
body .modal-image [role=dialog] img {
  max-width: 100%;
}
body .modal-image [role=dialog] button {
  border: none;
  background-color: transparent;
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0px;
}
body .modal-image [role=dialog] button i {
  font-size: 30px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  color: #CCC !important;
}
body .modal-thanks .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.85);
}
body .modal-thanks [role=dialog] {
  border-radius: 19px;
  padding: 65px 0px;
}
body .modal-thanks [role=dialog] h1 {
  font-family: "Cormorant", serif;
  font-size: 73px;
  text-align: center;
  display: inline-block;
  width: 100%;
  color: #004072;
  font-weight: 200;
  margin-bottom: 20px;
  position: relative;
  vertical-align: middle;
  line-height: 80px;
}
body .modal-thanks [role=dialog] h1::before {
  display: inline-block;
  vertical-align: middle;
  content: " ";
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #0cbf43;
  margin-top: -15px;
  margin-right: 10px;
}
body .modal-thanks [role=dialog] p {
  display: block;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: justify;
  margin: 0 auto;
  width: 70%;
  color: #004072;
  margin-bottom: 45px;
}
body .modal-thanks [role=dialog] a {
  display: inline-block;
  height: 48px;
  line-height: 48px;
  vertical-align: middle;
  text-align: center;
  padding: 0px 40px;
  border-radius: 34px;
  background-color: #004072;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
}
body .modal-thanks [role=dialog] a.green {
  background-color: #0cbf43;
}

@media only screen and (min-width: 960px) and (max-width: 1340px) {
  header .bottom nav a {
    padding: 0px 8px !important;
    font-size: 14px !important;
  }
  header .bottom nav a:last-child {
    margin-right: 0px !important;
  }

  main {
    width: 920px !important;
    padding: 0px 20px !important;
  }
  main .acabados {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr)) !important;
  }
  main .acabados .img-holder {
    height: 120px !important;
  }
  main .fields {
    display: grid !important;
  }
  main .fields fieldset label {
    text-align: left !important;
  }
  main .step section .summary {
    margin-bottom: 60px;
  }
  main .step section .main-products-holder {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  main .step section .main-products-holder .main-product-item {
    flex-basis: 40% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  main .step section .options button.acabado {
    width: 350px !important;
  }
  main .step section .options button.acabado .img-holder {
    background-size: cover !important;
    height: 200px !important;
  }

  footer .icons-holder {
    padding: 0px 60px;
  }
  footer .icons-holder .icon a img {
    height: 80px !important;
  }
  footer .icons-holder .icon a b {
    font-size: 16px !important;
  }
  footer .icons-holder .icon a p {
    font-size: 14px !important;
  }
  footer .site-map {
    padding: 0px 20px 80px 20px !important;
  }
  footer .site-map h2 {
    margin: 20px 0px !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 959px) {
  header .bottom a.logo-holder {
    width: 180px !important;
    display: block !important;
    margin: 0 auto 20px auto !important;
  }
  header .bottom nav {
    text-align: center !important;
  }
  header .bottom nav a {
    padding: 0px 8px !important;
    font-size: 14px !important;
    height: 30px !important;
    line-height: 30px !important;
  }
  header .bottom nav a:last-child {
    margin-right: 0px !important;
    display: block !important;
    width: 120px;
    margin: 10px auto 10px auto !important;
    height: 44px !important;
    line-height: 44px !important;
  }

  main {
    width: 590px !important;
    padding: 0px 20px !important;
  }
  main .welcome {
    padding-top: 40px;
  }
  main .welcome h1 {
    font-size: 48px !important;
  }
  main .acabados {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr)) !important;
  }
  main .acabados .img-holder {
    height: 80px !important;
  }
  main .fields {
    display: grid !important;
  }
  main .fields fieldset label {
    text-align: left !important;
  }
  main .step section .summary {
    margin-bottom: 60px;
  }
  main .step section .options button .content .img-holder {
    min-width: 100px !important;
    height: 110px !important;
    margin-right: 16px !important;
  }
  main .step section .options button .content a {
    font-size: 14px !important;
  }
  main .step section .options button.acabado {
    width: 50% !important;
  }
  main .step section .options button.acabado .content .img-holder {
    background-size: cover !important;
    height: 180px !important;
  }
  main .step section .main-products-holder {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  main .step section .main-products-holder .main-product-item {
    flex-basis: 35% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  main .step section .main-products-holder .main-product-item h2 {
    font-size: 20px !important;
  }

  footer .icons-area h2 {
    font-size: 48px !important;
  }
  footer .icons-area h3 {
    line-height: 22px;
    padding: 0px 40px;
  }
  footer .icons-area .icons-holder {
    padding: 0px 60px;
    display: block !important;
    text-align: center;
  }
  footer .icons-area .icons-holder .icon {
    display: inline-block !important;
    width: 28% !important;
    margin-bottom: 30px;
    padding: 0px 10px;
  }
  footer .icons-area .icons-holder .icon a img {
    height: 60px !important;
  }
  footer .icons-area .icons-holder .icon a b {
    font-size: 16px !important;
  }
  footer .icons-area .icons-holder .icon a p {
    font-size: 12px !important;
  }
  footer .site-map {
    padding: 0px 20px 80px 20px !important;
  }
  footer .site-map .social-icons {
    margin-bottom: 30px;
  }
  footer .site-map h2 {
    margin: 20px 0px !important;
  }
  footer .site-map ul {
    margin-bottom: 40px;
  }

  .modal-thanks [role=dialog] {
    max-width: 580px !important;
  }
}
@media only screen and (max-width: 599px) {
  header .bottom a.logo-holder {
    width: 180px !important;
    display: block !important;
    margin: 0 auto 20px auto !important;
  }
  header .bottom nav {
    text-align: center !important;
  }
  header .bottom nav a {
    padding: 0px 8px !important;
    font-size: 14px !important;
    height: 30px !important;
    line-height: 30px !important;
  }
  header .bottom nav a:last-child {
    margin-right: 0px !important;
    display: block !important;
    width: 120px;
    margin: 10px auto 10px auto !important;
    height: 44px !important;
    line-height: 44px !important;
  }

  main {
    width: 90% !important;
    padding: 0px 20px !important;
  }
  main .welcome {
    padding-top: 40px;
  }
  main .welcome h1 {
    font-size: 48px !important;
  }
  main .acabados-holder {
    margin: 0px !important;
    padding: 0px !important;
  }
  main .acabados-holder .acabados {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr)) !important;
    width: 100%;
    padding: 0px !important;
  }
  main .acabados-holder .acabados .img-holder {
    height: 150px !important;
  }
  main .fields {
    display: grid !important;
  }
  main .fields fieldset label {
    text-align: left !important;
  }
  main .step big {
    display: block;
    font-size: 22px !important;
    line-height: 26px !important;
    margin-bottom: 20px !important;
  }
  main .step section {
    padding-left: 0px !important;
  }
  main .step section .summary {
    margin-bottom: 60px;
  }
  main .step section h3 {
    font-size: 26px !important;
  }
  main .step section .main-products-holder {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  main .step section .main-products-holder .main-product-item {
    flex-basis: 35% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  main .step section .main-products-holder .main-product-item h2 {
    font-size: 18px !important;
    height: 45px;
  }
  main .step section .main-products-holder .main-product-item .img-holder {
    height: 100px !important;
  }
  main .step section .options {
    max-width: 80% !important;
    margin: 0px auto !important;
  }
  main .step section .options .slick-arrow {
    left: -45px !important;
  }
  main .step section .options .slick-arrow.r {
    left: unset !important;
    right: -45px !important;
  }
  main .step section .options button .content .img-holder {
    min-width: 100px !important;
    height: 190px !important;
    margin-right: 16px !important;
  }
  main .step section .options button .content a {
    font-size: 14px !important;
  }
  main .step section .options button.acabado {
    width: 50% !important;
    max-width: unset !important;
    padding-bottom: 10px !important;
  }
  main .step section .options button.acabado .content .img-holder {
    background-size: cover !important;
    height: 120px !important;
  }
  main .step .fields fieldset select, main .step .fields fieldset input {
    height: 50px !important;
  }

  footer .icons-area h2 {
    font-size: 48px !important;
  }
  footer .icons-area h3 {
    line-height: 22px;
    padding: 0px 40px;
  }
  footer .icons-area .icons-holder {
    padding: 0px 60px;
    display: block !important;
    text-align: center;
  }
  footer .icons-area .icons-holder .icon {
    display: inline-block !important;
    width: 40% !important;
    margin-bottom: 30px;
    padding: 0px 10px;
  }
  footer .icons-area .icons-holder .icon a img {
    height: 60px !important;
  }
  footer .icons-area .icons-holder .icon a b {
    font-size: 16px !important;
  }
  footer .icons-area .icons-holder .icon a p {
    font-size: 12px !important;
  }
  footer .site-map {
    padding: 0px 20px 80px 20px !important;
  }
  footer .site-map .social-icons {
    margin-bottom: 30px;
  }
  footer .site-map h2 {
    margin: 20px 0px !important;
  }
  footer .site-map ul {
    margin-bottom: 40px;
  }

  .modal-thanks [role=dialog] {
    max-width: 380px !important;
  }
  .modal-thanks [role=dialog] h1 {
    font-size: 48px !important;
    line-height: 48px !important;
  }
  .modal-thanks [role=dialog] p {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .modal-thanks [role=dialog] a {
    padding: 0px 20px !important;
    font-size: 14px !important;
    height: 36px !important;
    line-height: 36px !important;
  }
}

