$mui-xl: 1920px;
$mui-lg: 1280px;
$mui-md: 920px;
$mui-sm: 590px; //560px
$mui-xs: 90%; //380px;

body{
    font-family: 'Assistant', sans-serif;
    margin: 0px;
    padding: 0px;
    scroll-behavior: smooth;
    .img-magnifier{
        position: absolute;
        display: inline-block;
        left:0px;
        top:0px;
        z-index: 999;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        vertical-align: middle;
        background-color: rgba(255,255,255, 0.55);
        i{
            color: #004072;
            font-size: 14px;
        }
    }
    header{
        .top{
            background-color: #949494;
            padding: 6px 20px;
            a{
                display: inline-block;
                color: #fff;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.31;
                letter-spacing: normal;
                text-align: center;
                margin-right: 35px;
                text-decoration: none;
                line-height: 20px;
                vertical-align: middle;
                i{
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 12px;
                    margin-right: 4px;
                }
            }
        }
        .bottom{
            padding: 6px 20px;
            background-color: #f0edea;
            border-left: 14px solid #000b1b;
            vertical-align: middle;
            img{
                display: inline-block;
                height: 50px;
                vertical-align: middle;
                margin-top: 15px;
            }
            nav{
                text-align: right;
                a{
                    display: inline-block;
                    height: 80px;
                    line-height: 80px;
                    vertical-align: middle;
                    text-align: center;
                    color: #707070;
                    font-size: 16px;
                    text-decoration: none;
                    padding: 0px 12px;
                    position: relative;
                    &::after{
                        content: " ";
                        position: absolute;
                        bottom: calc(50% - 15px);
                        width: 60px;
                        height: 2px;
                        background-color: #707070;
                        left:50%;
                        margin-left: -30px;
                        opacity: 0;
                        transition: all 0.35s;
                    }
                    &:hover{
                        &::after{
                            opacity: 1;
                        }
                    }
                    &:last-child{
                        background-color: #004072;
                        color: #fff;
                        text-align: center;
                        height: 44px;
                        line-height: 44px;
                        padding: 0px 25px;
                        margin-right: 60px;
                        margin-left: 20px;
                        opacity: 0.75;
                        transition: all 0.35s;
                        &:hover{
                            opacity: 1;
                            &::after{
                                display: none;
                            }
                        }
                    }

                }
            }
        }
    }
    main{
        width: $mui-lg;
        margin: 0px auto;
        padding: 80px 20px;

        // Welocome
        .welcome{
            margin-bottom: 80px;
            h1{
                font-family: 'Cormorant', serif;
                font-size: 73px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.21;
                letter-spacing: normal;
                text-align: left;
                color: #004072;
            }
            p{
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.3;
                letter-spacing: normal;
                text-align: left;
                color: #004072;
            }
        }

        // Step and instruction
        .step{
            margin-bottom: 110px;
            border-bottom: 1px solid #004072;
            padding-bottom: 40px;
            &.hidden{
                display: none;
            }
            big{
                font-size: 36px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.67;
                letter-spacing: normal;
                text-align: left;
                color: #004072;
                margin-bottom: 10px;
            }
            section{
                padding-left: 45px;
                legend{
                    display: block;
                    font-size: 20px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.3;
                    letter-spacing: normal;
                    text-align: left;
                    color: #004072;
                    margin-bottom: 20px;
                    b{
                        display: block;
                        font-size: 20px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.3;
                        letter-spacing: normal;
                        text-align: left;
                        color: #ff0000;
                        margin-bottom: 70px;
                        a{
                            color: #ff0000;
                            text-decoration: none;
                        }
                    }
                }
                // Productos principales:
                .main-products-holder{
                    display:flex;
                    align-items:center;
                    .main-product-item{
                        flex-basis: 0;
                        flex-grow: 1;
                        max-width: 100%;
                        margin: 0px 20px;
                        cursor: pointer;
                        border: solid 3px #FFF;
                        padding:15px;
                        border-radius: 28px;
                        &:first-child{
                            margin-left: 0px;
                        }
                        &:last-child{
                            margin-right: 0px;
                        }
                        h2{
                            font-size: 22px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            color: #004072;
                            text-decoration: underline;
                            margin-bottom: 10px;
                        }
                        .img-holder{
                            position: relative;
                            height: 240px;
                            width: 100%;
                            background-repeat: no-repeat;
                            background-position: top left;
                            background-size: cover;
                        }
                        &.selected{
                            border: solid 3px #0cbf43;
                        }
                    }
                }
                .options{
                    //display:flex;
                    //align-items:center;
                    font-size: 0px;
                    max-width: calc(100% - 80px);
                    .slick-arrow{
                        position: absolute;
                        top:50%;
                        margin-top: -45px;
                        width: 40px;
                        height: 40px;
                        text-align: center;
                        line-height: 40px;
                        vertical-align: middle;
                        font-size: 30px;
                        background: transparent;
                        border:none;
                        z-index: 2;
                        text-align: center;
                        padding:0px;
                        left: -40px;
                        cursor: pointer;
                        border: solid 2px #707070;
                        border-radius: 50%;
                        i{
                            display: inline-block;
                            width: 35px;
                            height: 35px;
                            vertical-align: middle;
                            text-align: center;
                            margin-top: -12px;
                            margin-left: 0px;
                        }
                        &.r{
                            left: unset;
                            right: -80px;
                            i{
                                margin-top: -12px;
                                margin-left: 6px;
                            }
                        }
                    }
                    .slick-dots{
                        display: none !important;
                    }
                    button{
                        background-color: transparent;
                        border:none;
                        text-align: left;
                        position: relative;
                        padding-bottom: 50px;
                        transition: all 0.35s;
                        &::after{
                            display: inline-block;
                            content: "Elegir";
                            position: absolute;
                            bottom: 0px;
                            left: 50%;
                            margin-left: -35px;
                            width: 70px;
                            height: 38px;
                            color: #fff;
                            font-size: 18px;
                            font-weight: bold;
                            background-color: #004072;
                            border-radius: 19px;
                            line-height: 38px;
                            vertical-align: middle;
                            text-align: center;
                            visibility: hidden;
                            opacity: 0;
                            transition: all 0.35s;
                        }
                        .content{
                            display: flex;
                            align-items: center;
                            border: solid 3px #FFF;
                            padding:15px;
                            border-radius: 28px;
                            overflow: hidden;
                            flex-wrap: wrap;
                            flex-direction: column;
                            a.link-title{
                                display: flex;
                                width: 100%;
                                font-size: 22px;
                                font-weight: 400;
                                color: #646464;
                                text-decoration: none;
                                margin-bottom: 15px;
                            }
                            .img-holder{
                                position: relative;
                                display: flex;
                                width: 100%;
                                vertical-align: top;
                                background-color: #f2f2f2;
                                min-width: 120px;
                                height: 152px;
                                margin-right: 24px;
                                transition: all 0.35s;
                                background-size: contain;
                                background-position: top center;
                                background-repeat: no-repeat;
                            }
                        }
                        &:hover{
                            opacity: 1;
                            &::after{
                                visibility: visible;
                                opacity: 1;
                            }
                            .content{
                                a{
                                    text-decoration: underline;
                                    color: #004072;
                                    text-decoration-thickness: 1px;
                                }
                                .img-holder{
                                    position: relative;
                                    box-shadow: 0px 3px 5px 0px rgba(179,179,179,0.81);
                                }
                            }
                        }
                        &.selected{
                            opacity: 1;
                            &::after{
                                opacity: 0;
                                visibility: hidden;
                            }
                            .content{
                                border: solid 3px #0cbf43;
                                a{
                                    color: #004072;
                                }
                            }
                        }
                        &.acabado{
                            width: 30%;
                            .img-holder{
                                background-size: contain;
                                height: 275px !important;
                            }
                        }
                    }
                }
                .form-holder{
                    .summary{
                        h2{
                            font-family: 'Cormorant', serif;
                            font-size: 40px;
                            text-align: left;
                            color: #ff0000;
                            margin-bottom: 28px;;
                        }
                        .img-holder{
                            position: relative;
                            width: 114px;
                            height: 114px;
                            background-position: top center;
                            background-repeat: no-repeat;
                            background-size: cover;
                        }
                        p{
                            font-size: 20px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.3;
                            letter-spacing: normal;
                            text-align: left;
                            color: #004072;
                        }
                    }
                    h3{
                        font-family: 'Cormorant', serif;
                        font-size: 40px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.2;
                        letter-spacing: -0.6px;
                        text-align: left;
                        color: #004072;
                        margin-bottom: 20px;
                    }
                    input[type="text"],textarea, select{
                        height: 43px;
                        border-radius: 25px;
                        background-color: #f2f2f2;
                        width: calc(100% - 30px);
                        padding: 0px 15px;
                        border:none;
                        &.error{
                            border: 1px solid red;
                        }
                    }
                    select{
                        width: 100%;
                    }
                    textarea{
                        height: 70px;
                        resize: none;
                        padding: 15px 15px;
                    }
                    button{
                        display: inline-block;
                        float: right;
                        height: 48.1px;
                        border-radius: 34px;
                        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
                        background-color: #7acc45;
                        border:none;
                        padding: 0px 60px;
                        color:#fff;
                    }
                    legend{
                        font-size: 15px;
                        color: #FF0000;
                        a{
                            color: #FF0000;
                            text-decoration: underline;
                        }
                    }
                }
            }
            h2{
                display: block;
                font-size: 20px;
                font-weight: bold;
            }
            .acabados{
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
                padding: 20px;
                .acabado-holder{
                    flex-basis: 0;
                    flex-grow: 1;
                    margin-bottom: 40px;
                    margin: 10px;
                    opacity: 0.65;
                    cursor: pointer;
                    transition: all 0.35s;
                    border: solid 3px #fff;
                    padding: 10px;
                    border-radius: 19px;
                    .img-holder{
                        height: 180px;
                        position: relative;
                    }
                    span{
                        display: block;
                        font-size: 14px;
                        font-weight: 300;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.21;
                        letter-spacing: 0.7px;
                        text-align: center;
                        color: #646464;
                        margin-top: 25px;
                    }
                    &:hover{
                        opacity: 1;
                    }
                    &.selected{
                        opacity: 1;
                        border: solid 3px #0cbf43;
                    }
                }
            }

            // Fields
            .fields{
                display:flex;
                align-items:center;
                align-content: center;
                fieldset{
                    flex-basis: 0;
                    flex-grow: 1;
                    padding:10px 10px;
                    input, select{
                        height: 80px;
                        border-radius: 25px;
                        border: solid 1px #707070;
                        vertical-align: top;
                        width: calc(100% - 40px);
                        padding: 0px 20px;
                    }
                    select{
                        width: 100%;
                    }
                    label{
                        font-size: 18px;
                        color: #004072;
                        display: block;
                        text-align: center;
                        strong{
                            display: block;
                            font-size: 14px;
                            line-height: 20px;
                            color: #ff0000;
                        }
                    }
                    &.error{
                        select, input{
                            border:1px solid red !important;
                        }
                    }
                }
            }
            small{
                font-size: 16px;
                border-top:1px solid #EDEDED;
                display: block;
                width: 100%;
                margin-top: 15px;
                padding-top: 15px;
                color: #FF0000;
                a{
                    color: #FF0000;
                }
            }
        }
    }
    footer{
        background-color: #004072;
        color:#fff;
        .icons-area{
            width: 100%;
            margin: 0px auto;
            padding: 60px 0px;
            h2{
                font-size: 60px;
                font-family: 'Cormorant', serif;
                text-align: center;
                border-bottom: 1px solid rgba(#fff, 0.45);
                margin-bottom: 20px;
                padding-bottom: 20px;
            }
            h3{
                font-size: 16px;
                text-align: center;
            }
            .icons-holder{
                display:flex;
                margin-top: 40px;
                .icon{
                    flex-basis: 0;
                    flex-grow: 1;
                    max-width: 1;
                    a{
                        display: inline-block;
                        width: 100%;
                        text-align: center;
                        color: #fff;
                        text-decoration: none;
                        img{
                            max-width: 100%;
                            height: 150px;
                        }
                        b{
                            display: inline-block;
                            width: 100%;
                            font-size: 20px;
                            text-transform: uppercase;
                            font-weight: bold;
                            margin-bottom: 10px;
                            vertical-align: top;
                        }
                        p{
                            display: inline-block;
                            width: 100%;
                            font-size: 16px;
                            text-transform: uppercase;
                            vertical-align: top;
                        }
                    }
                }
            }
        }
        .site-map{
            padding:80px 20px;
            text-align: center;
            .logo{
                display: inline-block;
                margin-bottom: 30px;
                img{
                    display: inline-block;
                    height: 40px;
                }
            }
            .social-icons{
                display:flex;
                a{
                    flex-basis: 0;
                    flex-grow: 1;
                    max-width: 1;
                    color: #fff;
                    i{
                        font-size: 30px;
                    }
                }
            }
            h2{
                font-family: 'Cormorant', serif;
                font-size: 20px;
                font-weight: 600;
                display: block;
                text-align: left;
                margin-bottom: 15px;
            }
            ul{
                li{
                    display: block;
                    text-align: left;
                    margin-bottom: 7px;
                    a{
                        display: block;
                        color: rgba(#fff, 0.45);
                        text-decoration: none;
                    }
                }
            }
            address{
                text-align: left;
                margin-bottom: 20px;
                h3{
                    display: block;
                    width: 100%;
                    font-weight: 600;
                    margin-bottom: 5px;
                }
                a{
                    display: inline-block;
                    width: 100%;
                    line-height: 20px;
                    color: rgba(#fff, 0.65);
                    margin-bottom: 5px;
                    text-decoration: none;
                }
                p{
                    display: inline-block;
                    width: 100%;
                    line-height: 20px;
                    color: rgba(#fff, 0.65);
                    margin-bottom: 5px;
                }
            }
        }
        .copyright{
            display: block;
            background-color: #dad7d3;
            color: #707070;
            padding: 10px 0px;
            font-size: 13px;
            text-align: center;
        }
    }



    .modal-image{
        position: relative;
        .MuiBackdrop-root{
            background-color:rgba(#000000, 0.85);
        }
        [role="dialog"]{
            border-radius: 19px;
            padding: 65px 0px;
            img{
                max-width: 100%;
            }
            button{
                border:none;
                background-color: transparent;
                position: absolute;
                top:10px;
                right:10px;
                margin: 0px;
                i{
                    font-size: 30px;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    vertical-align: middle;
                    color: #CCC !important;
                }
            }
        }
    }


    .modal-thanks{
        .MuiBackdrop-root{
            background-color:rgba(#000000, 0.85);
        }
        [role="dialog"]{
            border-radius: 19px;
            padding: 65px 0px;
            h1{
                font-family: 'Cormorant', serif;
                font-size: 73px;
                text-align: center;
                display: inline-block;
                width: 100%;
                color: #004072;
                font-weight: 200;
                margin-bottom: 20px;
                position: relative;
                vertical-align: middle;
                line-height: 80px;
                &::before{
                    display: inline-block;
                    vertical-align: middle;
                    content: " ";
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    background-color: #0cbf43;
                    margin-top: -15px;
                    margin-right: 10px;
                }
            }
            p{
                display: block;
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.3;
                letter-spacing: normal;
                text-align: justify;
                margin:0 auto;
                width: 70%;
                color: #004072;
                margin-bottom: 45px;
            }
            a{
                display: inline-block;
                height: 48px;
                line-height: 48px;
                vertical-align: middle;
                text-align: center;
                padding: 0px 40px;
                border-radius: 34px;
                background-color: #004072;
                color: #fff;
                text-decoration: none;
                font-size: 20px;
                font-weight: bold;
                &.green{
                    background-color: #0cbf43;
                }
            }
        }
    }
}





@media only screen and (min-width: 1280px) {}
@media only screen and (min-width: 960px) and (max-width:1340px){
    header{
        .bottom{
            nav{
                a{
                    padding: 0px 8px !important;
                    font-size: 14px !important;
                    &:last-child{
                        margin-right: 0px !important;
                    }
                }
            }
        }
    }
    main{
        width: $mui-md !important;
        padding: 0px 20px !important;
        .acabados{
            grid-template-columns: repeat(auto-fill, minmax(25%, 1fr)) !important;
            .img-holder{
                height: 120px !important;
            }
        }
        .fields{
            display: grid !important;
            fieldset{
                label{
                    text-align: left !important;
                }
            }
        }
        .step{
            section{
                .summary{
                    margin-bottom: 60px;
                }
                // Productos principales:
                .main-products-holder{
                    display:flex;
                    align-items:center;
                    flex-wrap: wrap;
                    .main-product-item{
                        flex-basis: 40% !important;
                        margin-left: 0px !important;
                        margin-right: 0px !important;
                    }
                }
                .options{
                    button.acabado{
                        width: 350px !important;
                        .img-holder{
                            background-size: cover !important;
                            height: 200px !important;
                        }
                    }
                }
            }
        }
    }
    footer{
        .icons-holder{
            padding: 0px 60px;
            .icon{
                a{
                    img{
                        height: 80px !important;
                    }
                    b{
                        font-size: 16px !important;
                    }
                    p{
                        font-size: 14px !important;
                    }
                }
            }
        }
        .site-map{
            padding: 0px 20px 80px 20px !important;
            h2{
                margin: 20px 0px !important;
            }
        }
    }
}
@media only screen and (min-width: 600px)  and (max-width:959px){
    header{
        .bottom{
            a.logo-holder{
                width: 180px !important;
                display: block !important;
                margin: 0 auto 20px auto !important;
            }
            nav{
                text-align: center !important;
                a{
                    padding: 0px 8px !important;
                    font-size: 14px !important;
                    height: 30px !important;
                    line-height: 30px !important;
                    &:last-child{
                        margin-right: 0px !important;
                        display: block !important;
                        width: 120px;
                        margin: 10px auto 10px auto !important;
                        height: 44px !important;
                        line-height: 44px !important;
                    }
                }
            }
        }
    }
    main{
        width: $mui-sm !important;
        padding: 0px 20px !important;
        .welcome{
            padding-top: 40px;
            h1{
                font-size: 48px !important;
            }
        }
        .acabados{
            grid-template-columns: repeat(auto-fill, minmax(33%, 1fr)) !important;
            .img-holder{
                height: 80px !important;
            }
        }
        .fields{
            display: grid !important;
            fieldset{
                label{
                    text-align: left !important;
                }
            }
        }
        .step{
            section{
                .summary{
                    margin-bottom: 60px;
                }
                .options{
                    button{
                        .content{
                            .img-holder{
                                min-width: 100px !important;
                                height: 110px !important;
                                margin-right: 16px !important;
                            }
                            a{
                                font-size: 14px !important;
                            }
                        }
                        &.acabado{
                            width: 50% !important;
                            .content{
                                .img-holder{
                                    background-size: cover !important;
                                    height: 180px !important;
                                }
                            }
                        }
                    }
                }
                // Productos principales:
                .main-products-holder{
                    display:flex;
                    align-items:center;
                    flex-wrap: wrap;
                    .main-product-item{
                        flex-basis: 35% !important;
                        margin-left: 0px !important;
                        margin-right: 0px !important;
                        h2{
                            font-size: 20px !important;
                        }
                    }
                }
            }
        }
    }
    footer{
        .icons-area{
            h2{
                font-size: 48px !important;
            }
            h3{
                line-height: 22px;
                padding: 0px 40px;
            }
            .icons-holder{
                padding: 0px 60px;
                display: block !important;
                text-align: center;
                .icon{
                    display: inline-block !important;
                    width: 28% !important;
                    margin-bottom: 30px;
                    padding: 0px 10px;
                    a{
                        img{
                            height: 60px !important;
                        }
                        b{
                            font-size: 16px !important;
                        }
                        p{
                            font-size: 12px !important;
                        }
                    }
                }
            }
        }
        .site-map{
            padding: 0px 20px 80px 20px !important;
            .social-icons{
                margin-bottom: 30px;
            }
            h2{
                margin: 20px 0px !important;
            }
            ul{
                margin-bottom: 40px;
            }
        }
    }

    .modal-thanks{
        [role="dialog"]{
            max-width: 580px !important;
        }
    }

}
@media only screen and (max-width:599px){
    header{
        .bottom{
            a.logo-holder{
                width: 180px !important;
                display: block !important;
                margin: 0 auto 20px auto !important;
            }
            nav{
                text-align: center !important;
                a{
                    padding: 0px 8px !important;
                    font-size: 14px !important;
                    height: 30px !important;
                    line-height: 30px !important;
                    &:last-child{
                        margin-right: 0px !important;
                        display: block !important;
                        width: 120px;
                        margin: 10px auto 10px auto !important;
                        height: 44px !important;
                        line-height: 44px !important;
                    }
                }
            }
        }
    }
    main{
        width: $mui-xs !important;
        padding: 0px 20px !important;
        .welcome{
            padding-top: 40px;
            h1{
                font-size: 48px !important;
            }
        }
        .acabados-holder{
            margin:0px !important;
            padding:0px !important;
            .acabados{
                grid-template-columns: repeat(auto-fill, minmax(50%, 1fr)) !important;
                width: 100%;
                padding: 0px !important;
                .img-holder{
                    height: 150px !important;
                }
            }
        }
        .fields{
            display: grid !important;
            fieldset{
                label{
                    text-align: left !important;
                }
            }
        }
        .step{
            big{
                display: block;
                font-size: 22px !important;
                line-height: 26px !important;
                margin-bottom: 20px !important;
            }
            section{
                padding-left: 0px !important;
                .summary{
                    margin-bottom: 60px;
                }
                h3{
                    font-size: 26px !important;
                }
                // Productos principales:
                .main-products-holder{
                    display:flex;
                    align-items:center;
                    flex-wrap: wrap;
                    .main-product-item{
                        flex-basis: 35% !important;
                        margin-left: 0px !important;
                        margin-right: 0px !important;
                        h2{
                            font-size: 18px !important;
                            height: 45px;
                        }
                        .img-holder{
                            height: 100px !important;
                        }
                    }
                }
                .options{
                    max-width: 80% !important;
                    margin: 0px auto !important;
                    .slick-arrow{
                        left: -45px !important;
                        &.r{
                            left: unset !important;
                            right: -45px !important;
                        }
                    }
                    button{
                        .content{
                            .img-holder{
                                min-width: 100px !important;
                                height: 190px !important;
                                margin-right: 16px !important;
                            }
                            a{
                                font-size: 14px !important;
                            }
                        }
                        &.acabado{
                            width: 50% !important;
                            max-width: unset !important;
                            padding-bottom: 10px !important;
                            .content{
                                .img-holder{
                                    background-size: cover !important;
                                    height: 120px !important;
                                }
                            }
                        }
                    }
                }
            }
            .fields{
                fieldset{
                    select, input{
                        height: 50px !important;
                    }
                }
            }
        }
    }
    footer{
        .icons-area{
            h2{
                font-size: 48px !important;
            }
            h3{
                line-height: 22px;
                padding: 0px 40px;
            }
            .icons-holder{
                padding: 0px 60px;
                display: block !important;
                text-align: center;
                .icon{
                    display: inline-block !important;
                    width: 40% !important;
                    margin-bottom: 30px;
                    padding: 0px 10px;
                    a{
                        img{
                            height: 60px !important;
                        }
                        b{
                            font-size: 16px !important;
                        }
                        p{
                            font-size: 12px !important;
                        }
                    }
                }
            }
        }
        .site-map{
            padding: 0px 20px 80px 20px !important;
            .social-icons{
                margin-bottom: 30px;
            }
            h2{
                margin: 20px 0px !important;
            }
            ul{
                margin-bottom: 40px;
            }
        }
    }
    .modal-thanks{
        [role="dialog"]{
            max-width: 380px !important;
            h1{
                font-size: 48px !important;
                line-height: 48px !important;
            }
            p{
                font-size: 14px !important;
                line-height: 20px !important;
            }
            a{
                padding: 0px 20px !important;
                font-size: 14px !important;
                height: 36px !important;
                line-height: 36px  !important;
            }
        }
    }
}